<!--
Composant


-->
<template>
  <div>
    <div class="row">
      <div class="col s12"></div>
    </div>
    <!-- {{type, organisme &   Site Web Officiel}} -->
    <div class="row z-depth-1">
      <div class="col s12">
        <div class="bt row"></div>
        <p class="light-blue-text text-darken-4">
          {{ dataText.e2OFormulaireTitre }}
        </p>
        <div class="input-field col s12 m4 l12">
          <input
            id="Norganisme"
            type="text"
            class="validate"
            name="Norganisme"
            v-model="isniForm.donneeIdentification.NomPrincipalOrganisme"
          />
          <label
            for="Norganisme"
            v-bind:class="{
              active:
                !isniForm.donneeIdentification.NomPrincipalOrganisme == '',
              error: hasError
            }"
          >
            {{ dataText.e2OIdpubliqueInfosInputNomLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e2OIdpubliqueInfosInputNomAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
        <div class="input-field col s12 m4 l6">
          <select
            v-model="isniForm.donneeIdentification.typeOrganisme"
            name="Torganisme"
          >
            <option value disabled selected>{{
              dataText.eCListeTexteParDefaut
            }}</option>
            <option
              v-for="(ref, index) in dataSelect.RefTypeOrganisme"
              :value="index"
              :key="index"
              >{{ ref }}</option
            >
          </select>
          <label :class="{ error: hasError }" for="Torganisme">
            {{ dataText.e2OIdpubliqueInfosListeTypeorganismeLabel }}
            <span class="red-text">*</span>
          </label>
        </div>
        <div class="input-field col s12 m4 l6">
          <input
            id="Sinternet"
            type="text"
            class="validate"
            v-model="isniForm.donneeIdentification.siteInternet"
          />
          <label
            for="Sinternet"
            v-bind:class="{
              active: !isniForm.donneeIdentification.siteInternet == ''
            }"
          >
            {{ dataText.e2CIdpubliqueTypeInputSiteLabel }}
            <Isni-Tooltip :content="dataText.e2OIdpubliqueTypeInputSiteAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>

        <app-date-organisme
          :isniForm="isniForm"
          :dataText="dataText"
          :dataSelect="dataSelect"
          :hasError="hasError"
          :errorDetail="errorDetail"
        ></app-date-organisme>

        <!-- {{ Lieu d'activité }} -->
        <div class="col s12">
          <!-- {{ Lieu d'activité - pays }} -->
          <p class="light-blue-text text-darken-4">
            {{ dataText.e2OIdpubliqueLieuactiviteSoustitreTexte }}
            <Isni-Tooltip :content="dataText.e2OIdpubliqueLieuactiviteSoustitreAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </p>
          <div class="input-field col s12 m6 l4">
            <select
              v-model="isniForm.donneeIdentification.lieu.activite.pays"
              @change="onChangeCountryActivity($event)"
              name="Apays"
            >
              <option value disabled selected>{{
                dataText.eCListeTexteParDefaut
              }}</option>
              <option
                v-for="(ref, index) in dataSelect.RefPays"
                :value="index"
                :key="index"
                >{{ ref }}</option
              >
            </select>
            <label :class="{ error: hasError }" for="Apays">
              {{ dataText.e2OIdpubliqueLieuactiviteListePaysLabel }}
              <span class="red-text">*</span>
            </label>
          </div>

          <!-- {{ Lieu d'activité - Canada }} -->
          <div v-show="canadaActivity">
            <!-- {{ Lieu d'activité - regions du Canada }} -->
            <div class="input-field col s12 m6 l4">
              <select
                v-model="isniForm.donneeIdentification.lieu.activite.region"
                @change="onChangeRegion('activite')"
                name="Aregion"
              >
                <option value disabled selected>{{
                  dataText.e2OIdpubliqueLieusiegesocialListeProvinceLabel
                }}</option>
                <option
                  v-for="(ref, index) in dataSelect.RefRegion"
                  :value="index"
                  :key="index"
                  >{{ ref }}</option
                >
              </select>
              <label :class="{ error: hasError }" for="Aregion">
                {{ dataText.e2OIdpubliqueLieuactiviteListeProvinceLabel }}
                <span class="red-text">*</span>
              </label>
            </div>

            <!-- {{ Lieu d'activité - villes region Quebec }} -->
            <div
              class="input-field col s12 m12 l4"
              v-show="
                isniForm.donneeIdentification.lieu.activite.region === 'QC'
              "
              @change="validateCityQuebec('activite')"
            >
              <select
                id="Vactivity"
                required
                v-model="isniForm.donneeIdentification.lieu.activite.ville"
              >
                <option value disabled selected>{{
                  dataText.eCListeTexteParDefaut
                }}</option>
                <option
                  v-for="(ref, index) in dataSelect.RefVille"
                  :value="index"
                  :key="index"
                  >{{ ref }}</option
                >
              </select>
              <label
                for="Vactivity"
                v-bind:class="{
                  error: hasError,
                  active:
                    !isniForm.donneeIdentification.lieu.activite.ville == ''
                }"
              >
                {{ dataText.e2OIdpubliqueLieuactiviteListeVilleLabel }}
                <span class="red-text">*</span>
              </label>
            </div>

            <!-- {{ Lieu d'activité - villes region autres que Quebec }} -->
            <div
              class="input-field col s12 m12 l4"
              v-show="
                isniForm.donneeIdentification.lieu.activite.region != 'QC'
              "
            >
              <app-list-cities
                              inputId="Vactivity"
                              inputClass="validate"
                              lieu="activite"
                              :country-model="isniForm.donneeIdentification.lieu.activite.pays"
                              :region-model="isniForm.donneeIdentification.lieu.activite.region"
                              :city-model="isniForm.donneeIdentification.lieu.activite.ville"
                              placeholder=""
                              :first-item-text="dataText.eCListeTexteParDefaut"
                              v-on:selected="validateCitySelection"
              ></app-list-cities>
              <label class="active" for="Vactivity" :class="{ error: hasError }">
                {{ dataText.e2OIdpubliqueLieuactiviteListeVilleLabel }}
                <span class="red-text">*</span>
              </label>
            </div>
          </div>

          <!-- {{ Lieu d'activité - villes dans pays autres que Canada }} -->
          <div v-show="!canadaActivity">
            <div class="input-field col s12" :class="{m6 : isniForm.donneeIdentification.lieu.activite.region != 'QC', l8 : isniForm.donneeIdentification.lieu.activite.region != 'QC'}">
              <app-list-cities
                              inputId="Vactivity"
                              inputClass="validate"
                              lieu="activite"
                              :country-model="isniForm.donneeIdentification.lieu.activite.pays"
                              region-model=""
                              :city-model="isniForm.donneeIdentification.lieu.activite.ville"
                              placeholder=""
                              :first-item-text="dataText.eCListeTexteParDefaut"
                              v-on:selected="validateCitySelection"
              ></app-list-cities>
              <label class="active" for="Vactivity" :class="{ error: hasError }">
                {{ dataText.e2OIdpubliqueLieuactiviteListeVilleLabel }}
                <span class="red-text">*</span>
              </label>
            </div>
          </div>
        </div>
        <!-- {{ end Lieu d'activité }} -->

        <!-- {{ Lieu de siege social }} -->
        <div class="col s12">
          <!-- {{ Lieu siège social - pays }} -->
          <p class="light-blue-text text-darken-4">
            {{ dataText.e2OIdpubliqueLieusiegesocialSoustitreTexte }}
            <Isni-Tooltip :content="dataText.e2OIdpubliqueLieusiegesocialSoustitreAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </p>
          <div class="input-field col s12 m6 l4">
            <select
              @change="onChangeCountryHeadOffice($event)"
              v-model="isniForm.donneeIdentification.lieu.siegeSocial.pays"
              name="SsPays"
            >
              <option value disabled selected>{{
                dataText.eCListeTexteParDefaut
              }}</option>
              <option
                v-for="(ref, index) in dataSelect.RefPays"
                :value="index"
                :key="index"
                >{{ ref }}</option
              >
            </select>
            <label :class="{ error: hasError }" for="SsPays">
              {{ dataText.e2OIdpubliqueLieusiegesocialListePaysLabel }}
            </label>
          </div>

          <!-- {{ Lieu de siege social - Canada }} -->
          <div v-show="canadaHeadOffice">
            <!-- {{ Lieu de siege social - regions du Canada }} -->
            <div class="input-field col s12 m6 l4">
              <select
                v-model="isniForm.donneeIdentification.lieu.siegeSocial.region"
                @change="onChangeRegion('siegeSocial')"
                name="SsRegion"
              >
                <option value disabled selected>{{
                  dataText.eCListeTexteParDefaut
                }}</option>
                <option
                  v-for="(ref, index) in dataSelect.RefRegion"
                  :value="index"
                  :key="index"
                  >{{ ref }}</option
                >
              </select>
              <label :class="{ error: hasError }" for="SsRegion">
                {{ dataText.e2OIdpubliqueLieusiegesocialListeProvinceLabel }}
              </label>
            </div>

            <!-- {{ Lieu de siege social - villes region Quebec }} -->
            <div
              class="input-field col s12 m12 l4"
              v-show="
                isniForm.donneeIdentification.lieu.siegeSocial.region === 'QC'
              "
              @change="validateCityQuebec('siegeSocial')"
            >
              <select
                id="VsiegeSocial"
                required
                v-model="isniForm.donneeIdentification.lieu.siegeSocial.ville"
              >
                <option value disabled selected>{{
                  dataText.eCListeTexteParDefaut
                }}</option>
                <option
                  v-for="(ref, index) in dataSelect.RefVille"
                  :value="index"
                  :key="index"
                  >{{ ref }}</option
                >
              </select>
              <label
                for="VsiegeSocial"
                v-bind:class="{
                  error: hasError,
                  active:
                    !isniForm.donneeIdentification.lieu.siegeSocial.ville == ''
                }"
              >
                {{ dataText.e2OIdpubliqueLieusiegesocialListeVilleLabel }}
                <span class="red-text">*</span>
              </label>
            </div>

            <!-- {{ Lieu de siege social - villes region autres que Quebec }} -->
            <div
              class="input-field col s12 m12 l4"
              v-show="
                isniForm.donneeIdentification.lieu.siegeSocial.region != 'QC'
              "
            >
              <app-list-cities
                              inputId="VsiegeSocial"
                              inputClass="validate"
                              lieu="siegeSocial"
                              :country-model="isniForm.donneeIdentification.lieu.siegeSocial.pays"
                              :region-model="isniForm.donneeIdentification.lieu.siegeSocial.region"
                              :city-model="isniForm.donneeIdentification.lieu.siegeSocial.ville"
                              placeholder=""
                              :first-item-text="dataText.eCListeTexteParDefaut"
                              v-on:selected="validateCitySelection"
              ></app-list-cities>
              <label class="active" for="VsiegeSocial" :class="{ error: hasError }">
                {{ dataText.e2OIdpubliqueLieusiegesocialListeVilleLabel }}
              </label>
            </div>
          </div>

          <!-- {{ Lieu de siege social - villes dans pays autres que Canada }} -->
          <div v-show="!canadaHeadOffice">
            <div class="input-field col s12" :class="{m6 : isniForm.donneeIdentification.lieu.siegeSocial.region != 'QC', l8 : isniForm.donneeIdentification.lieu.siegeSocial.region != 'QC'}">
              <app-list-cities
                              inputId="VsiegeSocial"
                              inputClass="validate"
                              lieu="siegeSocial"
                              :country-model="isniForm.donneeIdentification.lieu.siegeSocial.pays"
                              region-model=""
                              :city-model="isniForm.donneeIdentification.lieu.siegeSocial.ville"
                              placeholder=""
                              :first-item-text="dataText.eCListeTexteParDefaut"
                              v-on:selected="validateCitySelection"
              ></app-list-cities>
              <label class="active" for="VsiegeSocial" :class="{ error: hasError }">
                {{ dataText.e2OIdpubliqueLieusiegesocialListeVilleLabel }}
              </label>
            </div>
          </div>
        </div>
        <!-- {{ end Lieu de siege social }} -->

        <!-- {{ domaine activite }} -->
        <div class="col s12">
          <p class="light-blue-text text-darken-4">
            {{ dataText.e2CIdpubliqueDomaineactiviteSoustitreTexte }}
            <Isni-Tooltip :content="dataText.e2CIdpubliqueDomaineactiviteSoustitreAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </p>
          <div class="input-field col s12 m12 l6">
            <select
              required
              v-model="isniForm.donneeIdentification.domaineActivite"
              name="Dactivite"
            >
              <option value disabled selected>{{
                dataText.eCListeTexteParDefaut
              }}</option>
              <option
                v-for="(ref, index) in dataSelect.RefDomaineActivite"
                :value="index"
                :key="index"
                >{{ ref }}</option
              >
            </select>
            <label :class="{ error: hasError }" for="Dactivite">
              {{ dataText.e2CIdpubliqueListeDomaineactiviteLabel }}
              <span class="red-text">*</span>
            </label>
          </div>
        </div>
        <!-- {{ end domaine activite }} -->
      </div>
    </div>

    <div class="row z-depth-1">
      <div class="col s12">
        <div class="bt row"></div>
        <p class="light-blue-text text-darken-4">
          {{ dataText.e2OIdassocienomSoustitreTexte }}
          <Isni-Tooltip :content="dataText.e2CIdassocienomSoustitreAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </p>
        <p>
          <label>
            <input
              name="group0"
              type="radio"
              class="with-gap"
              :value="false"
              v-model="
                isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                  .check
              "
            />
            <span class="black-text fw-400">{{ dataText.formRbNon }}</span>
          </label>
        </p>
        <p>
          <label>
            <input
              name="group0"
              type="radio"
              class="with-gap"
              :value="true"
              v-model="
                isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                  .check
              "
            />
            <span class="black-text fw-400">{{ dataText.formRbOui }}</span>
          </label>
        </p>
      </div>

      <div
        class="col s12"
        v-show="
          isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0.check ==
            true
        "
      >
        <div class="input-field col s12 m6 l6">
          <input
            id="Nidentifiant"
            type="text"
            class="validate"
            v-model="
              isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                .associeNom
            "
          />
          <label
            for="Nidentifiant"
            :class="{
              error: hasError,
              active:
                !isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                  .associeNom == ''
            }"
            >{{ dataText.e2CIdassocienomInputIdLabel }}
            <span class="red-text">*</span></label
          >
        </div>

        <div class="input-field col s12 m6 l6">
          <select
            v-model="
              isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept0
                .associeNomType
            "
            name="Tidentifiant"
          >
            <option value disabled selected>{{
              dataText.eCListeTexteParDefaut
            }}</option>
            <option
              v-for="(ref, index) in dataSelect.RefTypeAutreIdentifiant"
              :value="index"
              :key="index"
              >{{ ref }}</option
            >
          </select>
          <label :class="{ error: hasError }" for="Tidentifiant">
            {{ dataText.e2CIdassocienomListeTypeidLabel }}
            <span class="red-text">*</span>
          </label>
        </div>

        <div class="col s12">
          <p>
            <label>
              <input
                type="checkbox"
                :value="true"
                v-model="
                  isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept1
                    .check
                "
              />
              <template
                v-if="
                  !isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept1
                    .check
                "
              >
                <span class="black-text fw-400">{{
                  dataText.e2CIdassocienomCbAjoutoccurrenceTexte
                }}</span>
              </template>
              <template v-else>
                <span class="black-text fw-400">{{
                  dataText.e2CIdassocienomCbRetraitoccurrenceTexte
                }}</span>
              </template>
            </label>
          </p>
        </div>

        <app-section-dentifitant-nom-organisme-1
          :isniForm="isniForm"
          :hasError="hasError"
          :dataText="dataText"
          :dataSelect="dataSelect"
          v-show="
            isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept1.check
          "
        >
        </app-section-dentifitant-nom-organisme-1>

        <div class="col s12">
          <p>
            <label>
              <input
                type="checkbox"
                :value="true"
                v-model="
                  isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept2
                    .check
                "
              />
              <template
                v-if="
                  !isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept2
                    .check
                "
              >
                <span class="black-text fw-400">{{
                  dataText.e2CIdassocienomCbAjoutoccurrence2Texte
                }}</span>
              </template>
              <template v-else>
                <span class="black-text fw-400">{{
                  dataText.e2CIdassocienomCbRetraitoccurrence2Texte
                }}</span>
              </template>
            </label>
          </p>
        </div>
        <app-section-dentifitant-nom-organisme-2
          :isniForm="isniForm"
          :hasError="hasError"
          :dataText="dataText"
          :dataSelect="dataSelect"
          v-show="
            isniForm.donneeIdentification.nomAssocie.idNomAssocieAccept2.check
          "
        >
        </app-section-dentifitant-nom-organisme-2>
      </div>

      <div class="col s12">
        <p class="light-blue-text text-darken-4">
          {{ dataText.e2OVariantenomSoustitreTexte }}
          <Isni-Tooltip :content="dataText.e2CVariantenomSoustitreAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </p>
        <p>
          <label>
            <input
              name="group2"
              type="radio"
              class="with-gap"
              :value="false"
              v-model="isniForm.donneeIdentification.variante.idVariante0.check"
            />
            <span class="black-text fw-400">{{ dataText.formRbNon }}</span>
          </label>
        </p>
        <p>
          <label>
            <input
              name="group2"
              type="radio"
              class="with-gap"
              :value="true"
              v-model="isniForm.donneeIdentification.variante.idVariante0.check"
            />
            <span class="black-text fw-400">{{ dataText.formRbOui }}</span>
          </label>
        </p>

        <div
          class="col s12"
          v-if="
            isniForm.donneeIdentification.variante.idVariante0.check == true
          "
        >
          <div class="input-field col s12 m12 l12">
            <input
              id="Vnom"
              type="text"
              class="validate"
              v-model="isniForm.donneeIdentification.variante.idVariante0.nom"
            />
            <label
              for="Vnom"
              name="Vnom"
              :class="{
                error: hasError,
                active:
                  !isniForm.donneeIdentification.variante.idVariante0.nom == ''
              }"
            >
              {{ dataText.e2OVariantenomInputNomLabel }}
              <span class="red-text">*</span>
            </label>
          </div>

          <div class="col s12">
            <p>
              <label>
                <input
                  type="checkbox"
                  :value="true"
                  v-model="
                    isniForm.donneeIdentification.variante.idVariante1.check
                  "
                />
                <template
                  v-if="
                    !isniForm.donneeIdentification.variante.idVariante1.check
                  "
                >
                  <span class="black-text fw-400">{{
                    dataText.e2CVariantenomCbAjoutoccurrence1Texte
                  }}</span>
                </template>
                <template v-else>
                  <span class="black-text fw-400">{{
                    dataText.e2CVariantenomCbRetraitoccurrence1Texte
                  }}</span>
                </template>
              </label>
            </p>
          </div>
          <app-section-variante-nom-organisme-1
            :isniForm="isniForm"
            :hasError="hasError"
            :dataText="dataText"
            v-show="isniForm.donneeIdentification.variante.idVariante1.check"
          >
          </app-section-variante-nom-organisme-1>
          <div class="col s12">
            <p>
              <label>
                <input
                  type="checkbox"
                  :value="true"
                  v-model="
                    isniForm.donneeIdentification.variante.idVariante2.check
                  "
                />
                <template
                  v-if="
                    !isniForm.donneeIdentification.variante.idVariante2.check
                  "
                >
                  <span class="black-text fw-400">{{
                    dataText.e2CVariantenomCbAjoutoccurrence2Texte
                  }}</span>
                </template>
                <template v-else>
                  <span class="black-text fw-400">{{
                    dataText.e2CVariantenomCbRetraitoccurrence2Texte
                  }}</span>
                </template>
              </label>
            </p>
          </div>
          <app-section-variante-nom-organisme-2
            :isniForm="isniForm"
            :hasError="hasError"
            :dataText="dataText"
            v-show="isniForm.donneeIdentification.variante.idVariante2.check"
          >
          </app-section-variante-nom-organisme-2>

          <div class="col s12">
            <p>
              <label>
                <input
                  type="checkbox"
                  :value="true"
                  v-model="
                    isniForm.donneeIdentification.variante.idVariante3.check
                  "
                />
                <template
                  v-if="
                    !isniForm.donneeIdentification.variante.idVariante3.check
                  "
                >
                  <span class="black-text fw-400">{{
                    dataText.e2CVariantenomCbAjoutoccurrence3Texte
                  }}</span>
                </template>
                <template v-else>
                  <span class="black-text fw-400">{{
                    dataText.e2CVariantenomCbRetraitoccurrence3Texte
                  }}</span>
                </template>
              </label>
            </p>
          </div>

          <app-section-variante-nom-organisme-3
            :isniForm="isniForm"
            :hasError="hasError"
            :dataText="dataText"
            v-show="isniForm.donneeIdentification.variante.idVariante3.check"
          >
          </app-section-variante-nom-organisme-3>
          <div class="col s12">
            <p>
              <label>
                <input
                  type="checkbox"
                  :value="true"
                  v-model="
                    isniForm.donneeIdentification.variante.idVariante4.check
                  "
                />
                <template
                  v-if="
                    !isniForm.donneeIdentification.variante.idVariante4.check
                  "
                >
                  <span class="black-text fw-400">{{
                    dataText.e2CVariantenomCbAjoutoccurrence4Texte
                  }}</span>
                </template>
                <template v-else>
                  <span class="black-text fw-400">{{
                    dataText.e2CVariantenomCbRetraitoccurrence4Texte
                  }}</span>
                </template>
              </label>
            </p>
          </div>
          <app-section-variante-nom-organisme-4
            :isniForm="isniForm"
            :hasError="hasError"
            :dataText="dataText"
            v-show="isniForm.donneeIdentification.variante.idVariante4.check"
          >
          </app-section-variante-nom-organisme-4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateOrganisme from "./../elements/DateOrganisme";
import Icon from "./../elements/Icon";
import IsniTooltip from "./../elements/IsniTooltip";
import ListCities from "./../elements/ListCities";
import IdentifiantNomOrganisme1 from "./../sections/step2/IdentifiantNomOrganisme1";
import IdentifiantNomOrganisme2 from "./../sections/step2/IdentifiantNomOrganisme2";
import VarianteNomOrganisme1 from "./../sections/step2/VarianteNomOrganisme1";
import VarianteNomOrganisme2 from "./../sections/step2/VarianteNomOrganisme2";
import VarianteNomOrganisme3 from "./../sections/step2/VarianteNomOrganisme3";
import VarianteNomOrganisme4 from "./../sections/step2/VarianteNomOrganisme4";

export default {
  name: "FormOrgaStep2",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    "data-position": {
      type: String
    },
    "data-tooltip": {
      type: String
    },
    hasError: Boolean,
    errorDetail: {
      type: Object
    }
  },
  data() {
    return {
      canadaActivity: false,
      canadaHeadOffice: false
    };
  },
  methods: {
    onChangeCountryActivity(event) {
      if (event.target.value === "CA") {
        this.canadaActivity = true;
      } else {
        this.canadaActivity = false;
        this.isniForm.donneeIdentification.lieu.activite.region = "";
        this.isniForm.donneeIdentification.lieu.activite.loCode = "";
        this.isniForm.donneeIdentification.lieu.activite.ville = "";

      }
    },
    onChangeCountryHeadOffice(event) {
      if (event.target.value === "CA") {
        this.canadaHeadOffice = true;
      } else {
        this.canadaHeadOffice = false;
        this.isniForm.donneeIdentification.lieu.siegeSocial.region = "";
        this.isniForm.donneeIdentification.lieu.siegeSocial.loCode = "";
        this.isniForm.donneeIdentification.lieu.siegeSocial.ville = "";
      }
    },
    onChangeRegion(lieu) {
      this.isniForm.donneeIdentification.lieu[lieu].loCode = "";
      this.isniForm.donneeIdentification.lieu[lieu].ville = "";
    },
    validateCitySelection(selectedCity, lieu) {
      if (selectedCity && typeof selectedCity === "object") {
        this.isniForm.donneeIdentification.lieu[lieu].loCode = selectedCity.loCode || '';
        this.isniForm.donneeIdentification.lieu[lieu].ville = selectedCity.cityName;
      }
    },
    /*
     * Villes du Quebec jamais devront envoyer un loCode, meme si ce loCode a venu deja rempli
     **/
    validateCityQuebec(lieu) {
      this.isniForm.donneeIdentification.lieu[lieu].loCode = '';
    }
  },
  components: {
    Icon,
    IsniTooltip,
    "app-date-organisme": DateOrganisme,
    "app-list-cities": ListCities,
    "app-section-dentifitant-nom-organisme-1": IdentifiantNomOrganisme1,
    "app-section-dentifitant-nom-organisme-2": IdentifiantNomOrganisme2,
    "app-section-variante-nom-organisme-1": VarianteNomOrganisme1,
    "app-section-variante-nom-organisme-2": VarianteNomOrganisme2,
    "app-section-variante-nom-organisme-3": VarianteNomOrganisme3,
    "app-section-variante-nom-organisme-4": VarianteNomOrganisme4
  },
  created() {
    // enabled canadaActivity and canadaHeadOffice when whe arrive in the form with the value already filled
    this.canadaActivity = this.isniForm.donneeIdentification.lieu.activite.pays === "CA";
    this.canadaHeadOffice = this.isniForm.donneeIdentification.lieu.siegeSocial.pays === "CA";
  }
};
</script>
<style lang="scss" scoped>
.bt {
  border-top: 10px solid #039be5;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
</style>

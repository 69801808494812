<template>
  <div class="row-isni--content row">
    <div v-for="(dataText, index) in textFormulaire" :key="index">
      <div class="wrapper-isni--content col s12">
        <app-step-progress
          :length="4"
          :propsStep="step"
          :dataText="dataText"
          :title-step="dataText.accueilCCtaOrganismeTitre"
        ></app-step-progress>

        <section class="wrapper-form col s12">
          <app-list-error
            :errors="errors"
            :dataText="dataText"
          ></app-list-error>

          <form v-for="(isniForm, index) in isniFormsOrga" :key="index">
            <!-- STEP 1 -->
            <section class="step1" v-if="step == 1">
              <h3 class="light-blue-text text-darken-4">
                {{ dataText.e1CFormulaireTitre }}
              </h3>
              <!-- STEP -->
              <app-orga-step-1
                v-bind:isniForm="isniForm"
                :hasError="hasError"
                :dataText="dataText"
                :dataSelect="dataSelect"
              ></app-orga-step-1>
            </section>

            <!-- STEP 2 -->
            <section class="step2" v-if="step == 2">
              <app-orga-step-2
                v-bind:isniForm="isniForm"
                :hasError="hasError"
                :errorDetail="errorDetail"
                :dataText="dataText"
                :dataSelect="dataSelect"
              ></app-orga-step-2>
            </section>

            <!-- STEP 3 -->
            <section class="step3" v-if="step == 3">
              <app-orga-step-3
                v-bind:isniForm="isniForm"
                :hasError="hasError"
                :dataText="dataText"
                :dataSelect="dataSelect"
              ></app-orga-step-3>
            </section>

            <!-- STEP 4 -->
            <section class="step4" v-if="step == 4">
              <app-orga-step-4
                v-bind:isniForm="isniForm"
                :hasError="hasError"
                :dataText="dataText"
                :dataSelect="dataSelect"
              ></app-orga-step-4>
            </section>

            <!-- ButtonStep  -->

            <div class="wrapper-btn col s12 offset-m2 offset-l3 l10">
              <button
                id="backHome"
                type="button"
                class="btn waves-effect waves-light col s12 m4 l3 mt-5 mr-5 white-text amber darken-2 h-btn-amber"
                v-if="step == 1"
                @click="BackHome"
              >
                {{ dataText.eCCtaRetourLabel }}
                <!-- <router-link to="/" class="white-text">Retour</router-link> -->
              </button>
              <button
                t
                ype="button"
                id="previousStep"
                class="btn waves-effect light-blue darken-1 waves-light col s12 m4 l3 mt-5 h-btn-darken"
                v-if="step != 1"
                @click.prevent="previousStep"
                href="#isni-form"
              >
                <span class="d-flex end">
                  <i class="material-icons">keyboard_arrow_left</i>
                  {{ dataText.eCCtaPrecedentLabel }}
                </span>
              </button>
              <button
                type="button"
                id="nextStep"
                class="btn waves-effect waves-light blue darken-4 col offset-m1 offset-l1 s12 m4 l3 mt-5 h-btn-light"
                v-if="step != totalStep"
                @click.prevent="nextStep"
                href="#isni-form"
                :disabled="activButtonStep1"
                :class="{ disabled: activButtonStep1 }"
              >
                <span class="d-flex end">
                  {{ dataText.eCCtaSuivantLabel }}
                  <i class="material-icons">keyboard_arrow_right</i>
                </span>
              </button>

              <button
                type="button"
                id="sendForm"
                class="btn waves-effect waves-light green col offset-l1 s12 m6 l4 mt-5 h-btn-green"
                v-if="step == 4"
                @click.prevent="sendForm"
                :disabled="activButtonStep4"
                :class="{ disabled: activButtonStep4 }"
              >
                <span class="d-flex end">
                  {{ dataText.e4CCtaEnvoyerLabel }} &nbsp;
                  <i class="material-icons ml-5">send</i>
                </span>
              </button>
            </div>
          </form>
        </section>
      </div>

      <div class="row">
        <app-conditions :step="step" :dataText="dataText" />
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import FormOrgaStep1 from "./../components/form/steps/FormOrga-Step-1";
import FormOrgaStep2 from "./../components/form/steps/FormOrga-Step-2";
import FormOrgaStep3 from "./../components/form/steps/FormOrga-Step-3";
import FormOrgaStep4 from "./../components/form/steps/FormOrga-Step-4";
import StepProgress from "./../components/layout/StepProgress";
import ListErrors from "./../components/form/elements/ListErrors";
import Conditions from "./../components/form/elements/Conditions";
// @ is an alias to /src
import { Constants } from "@/constants";
//  Recupere les data du select
import { postApiService } from "@/services/postApi.service";
import { helperDataFormService } from "@/services/helperDataForm.service";
import { validateDateService } from "@/services/validateDate.service";

export default {
  name: "FormOrga",
  components: {
    "app-orga-step-1": FormOrgaStep1,
    "app-orga-step-2": FormOrgaStep2,
    "app-orga-step-3": FormOrgaStep3,
    "app-orga-step-4": FormOrgaStep4,
    "app-step-progress": StepProgress,
    "app-list-error": ListErrors,
    "app-conditions": Conditions,
  },
  props: {
    isniFormsOrga: {
      type: Object,
      required: true,
    },
    dataSelect: {
      type: Object,
      required: true,
    },
    textErreur: {
      type: Object,
      required: true,
    },
    textFormulaire: {
      type: Object,
      required: true,
    },
    jumpToStep: {
      type: Number,
      required: false,
    },
    validateEmail: {
      type: Boolean,
      required: false,
    },
  },
  data: function () {
    return {
      titleForm: "Demande de numero Isni pour Organisme",
      // initlisation des steps
      step: 1,
      totalStep: 4,
      hasError: false,
      errorDetail: {},
      errors: [],
      displayTitleOrganisme: true,
      displayTitlePersonne: false,
      formEn: [],
      fr: "fr",
      en: "en",
      json: [],
      dateDebut: null,
      dateFin: null,
      yearLimit: new Date().getFullYear(),
      typeForm: Constants.TYPE_FORM.orga,
    };
  },
  methods: {
    /**
     *
     * gestion du retour au top du formulaire (SPA)
     */
    goToTopForm() {
      return window.scrollTo(0, 200);
    },
    /**
     * gestion des Step
     * Validation des champs obigatoire
     */
    nextStep: function () {
      // retour haut du formulaire
      this.goToTopForm();
      // tableau des erreurs
      this.errors = [];
      this.errorDetail = {};

      //gestion afficahge des erreurs si il y en a ou pas
      if (!this.errors.length) {
        this.hasError = false;
      }

      // nettoyer les champs pas utilisées (helperDataForm)
      helperDataFormService.cleanupHelperDataForm(
        this.typeForm,
        this.isniFormsOrga.isniFormsOrga
      );

      // Gestion des erreurs sur les steps
      this.getErrorsStep1();
      this.getErrorsStep2();
      this.getErrorsStep3();

      // validation si pas d'erreur on passe a la next step
      if (!this.errors.length) {
        if (this.step === 1 && this.validateEmail) {
          this.sendEmailValidation();
        } else {
          this.step++;
        }
        return true;
      }
      if (this.errors.length > 0) {
        return false;
      }
      // avance dans les steps
      this.step++;
    },
    /**
     * Step precedente
     */
    previousStep: function () {
      // au retour de step on vide le tableau d'erreur
      this.errors = [];
      this.errorDetail = {};
      // re-initialise l'affichage des erreurs
      this.hasError = false;
      // retour haut du formulaire
      this.goToTopForm();
      this.step--;
    },
    //******* Envoie du formulaire */
    sendForm: function () {
      // gestion erreur step4
      this.getErrorsStep4();
      // si pas d'erreur
      if (!this.errors.length) {
        // appel du service post
        this.postForm();
      } else {
        // retour haut du formulaire
        this.goToTopForm();
      }
    },

    /**
     * Services
     */
    async postForm() {
      const postFormResult = await postApiService.postForm(this.isniFormsOrga);
      if (postFormResult && postFormResult.data) {
        const codeMsgForm = postFormResult.data.codeMsgForm;
        const paramsMsgForm = postFormResult.data.paramsMsgForm;
        this.$router.push({
          name: "Merci",
          params: { codeMsgForm: codeMsgForm, paramsMsgForm: paramsMsgForm },
        });
      } else {
        this.$router.push({
          name: "Merci",
          params: {
            codeMsgForm: "nCProbTechEnvoiFormulaireTexte",
            paramsMsgForm: {},
          },
        });
      }
    },
    async sendEmailValidation() {
      const emailValidationSentResult = await postApiService.sendEmailValidation(
        this.isniFormsOrga.isniFormsOrga.demandeur,
        this.typeForm
      );
      let messageKey = "nCProbEnvoiCourrielValidationTexte";
      if (
        emailValidationSentResult &&
        emailValidationSentResult.status == 200
      ) {
        messageKey = "nCEnvoiCourrielValidationTexte";
      }
      this.$router.push({
        name: "emailValidationSent",
        params: { messageKey: messageKey },
      });
    },

    /**
     ** Retour /
     */
    BackHome: function () {
      // this.$router.go(-1);
      this.$router.push("/");
    },
    /**
     * validation de l'adresse email
     */
    validEmail(email) {
      /* eslint-disable-next-line */
      var reg = new RegExp(
        /* eslint-disable-next-line */
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      );
      return reg.test(email);
    },
    /**
     * Gestion des erreurs dans la step1
     */
    getErrorsStep1() {
      if (this.step == 1) {
        // vérification des caractères non latins
        if (
          !this.isLatinCode(
            JSON.stringify(this.isniFormsOrga.isniFormsOrga.demandeur)
          )
        ) {
          this.errors.push(this.textErreur.formTexteLatinErreur);
          this.hasError = true;
        } else {
          // Si les champs est vide ou incorrect alors on affiche l'erreur

          if (!this.isniFormsOrga.isniFormsOrga.demandeur.langueFormulaire) {
            this.errors.push(this.textErreur.e1CListeLangueErreur);
            this.hasError = true;
          }
          /**
           ** Validation de l'adresse email
           */
          // si il est vide
          if (
            this.isniFormsOrga.isniFormsOrga.demandeur.email == null ||
            this.isniFormsOrga.isniFormsOrga.demandeur.email == ""
          ) {
            this.errors.push(this.textErreur.e1CInputCourrielErreur1);
            this.hasError = true;
            // validation email si le format n'est pas le bon
          } else if (
            !this.validEmail(this.isniFormsOrga.isniFormsOrga.demandeur.email)
          ) {
            this.errors.push(this.textErreur.e1CInputCourrielErreur2);
          }
        }
      }
    },
    /**
     * Gestion des erreurs dans la step2
     */
    getErrorsStep2() {
      if (this.step == 2) {
        // vérification des caractères non latins
        if (
          !this.isLatinCode(
            JSON.stringify(
              this.isniFormsOrga.isniFormsOrga.donneeIdentification
            )
          )
        ) {
          this.errors.push(this.textErreur.formTexteLatinErreur);
          this.hasError = true;
        } else {
          // Si les champs est vide ou incorrect alors on affiche l'erreur

          // nom
          if (
            !this.isniFormsOrga.isniFormsOrga.donneeIdentification
              .NomPrincipalOrganisme
          ) {
            this.errors.push(this.textErreur.e2OIdpubliqueInfosInputNomErreur);
            this.hasError = true;
          }
          // type orgnanisme
          if (
            !this.isniFormsOrga.isniFormsOrga.donneeIdentification.typeOrganisme
          ) {
            this.errors.push(
              this.textErreur.e2OIdpubliqueInfosListeTypeorganismeErreur
            );
            this.hasError = true;
          }

          // validate date 'debut'
          const resultValidateDateDebut = validateDateService.checkIndividualDate(
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.date
              .debutUtilisationNom,
            ">=",
            "DateDebut"
          );
          const _DateDebut = resultValidateDateDebut.fullDate || null;
          if (
            resultValidateDateDebut.error &&
            resultValidateDateDebut.error.hasError
          ) {
            const errorLabelKeys = resultValidateDateDebut.error.errorLabelKeys;
            this.hasError = true;
            this.errorDetail.dateDebutAll = true;
            if (this.textErreur) {
              for (let i = 0; i < errorLabelKeys.length; i++) {
                this.errors.push(this.textErreur[errorLabelKeys[i]]);
              }
            }
          }

          // validate date 'fin'
          const resultValidateDateFin = validateDateService.checkIndividualDate(
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.date
              .finUtilisationNom,
            ">",
            "DateFin"
          );
          const _DateFin = resultValidateDateFin.fullDate || null;
          if (
            resultValidateDateFin.error &&
            resultValidateDateFin.error.hasError
          ) {
            const errorLabelKeys = resultValidateDateFin.error.errorLabelKeys;
            this.hasError = true;
            this.errorDetail.dateFinAll = true;
            if (this.textErreur) {
              for (let i = 0; i < errorLabelKeys.length; i++) {
                this.errors.push(this.textErreur[errorLabelKeys[i]]);
              }
            }
          }

          // validate 'fin' vs 'debut'
          const firstDate = this.isniFormsOrga.isniFormsOrga
            .donneeIdentification.date.debutUtilisationNom;
          firstDate.fullDate = _DateDebut;
          const lastDate = this.isniFormsOrga.isniFormsOrga.donneeIdentification
            .date.finUtilisationNom;
          lastDate.fullDate = _DateFin;
          const resultCompareDates = validateDateService.compareDates(
            firstDate,
            lastDate,
            "DateFin"
          );
          if (resultCompareDates.error && resultCompareDates.error.hasError) {
            const errorLabelKeys = resultCompareDates.error.errorLabelKeys;
            this.hasError = true;
            this.errorDetail.decesAll = true;
            if (this.textErreur) {
              for (let i = 0; i < errorLabelKeys.length; i++) {
                this.errors.push(this.textErreur[errorLabelKeys[i]]);
              }
            }
          }

          // activite pays
          if (
            !this.isniFormsOrga.isniFormsOrga.donneeIdentification.lieu.activite
              .pays
          ) {
            this.errors.push(
              this.textErreur.e2OIdpubliqueLieuactiviteListePaysErreur
            );
            this.hasError = true;
          }
          // activite region
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.lieu.activite
              .pays == "CA"
          ) {
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.lieu
                .activite.region
            ) {
              this.errors.push(
                this.textErreur.e2OIdpubliqueLieuactiviteListeProvinceErreur
              );
              this.hasError = true;
            }
          }
          // activite ville
          if (
            !this.isniFormsOrga.isniFormsOrga.donneeIdentification.lieu.activite
              .ville
          ) {
            this.errors.push(
              this.textErreur.e2OIdpubliqueLieuactiviteListeVilleErreur
            );
            this.hasError = true;
          }
          if (
            !this.isniFormsOrga.isniFormsOrga.donneeIdentification
              .domaineActivite
          ) {
            this.errors.push(
              this.textErreur.e2CIdpubliqueListeDomaineactiviteErreur
            );
            this.hasError = true;
          }
          ///*********************** Variante
          // identifiant 0
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
              .idNomAssocieAccept0.check
          ) {
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
                .idNomAssocieAccept0.associeNom
            ) {
              this.errors.push(this.textErreur.e2CIdassocienomInputIdErreur);
              this.hasError = true;
            }
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
                .idNomAssocieAccept0.associeNomType
            ) {
              this.errors.push(
                this.textErreur.e2CIdassocienomListeTypeidErreur
              );
              this.hasError = true;
            }
          }
          // identifiant 1
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
              .idNomAssocieAccept1.check
          ) {
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
                .idNomAssocieAccept1.associeNom
            ) {
              this.errors.push(this.textErreur.e2CIdassocienomInputIdErreur);
              this.hasError = true;
            }
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
                .idNomAssocieAccept1.associeNomType
            ) {
              this.errors.push(
                this.textErreur.e2CIdassocienomListeTypeidErreur
              );
              this.hasError = true;
            }
          }
          // identifiant 2
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
              .idNomAssocieAccept2.check
          ) {
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
                .idNomAssocieAccept2.associeNom
            ) {
              this.errors.push(this.textErreur.e2CIdassocienomInputIdErreur);
              this.hasError = true;
            }
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.nomAssocie
                .idNomAssocieAccept2.associeNomType
            ) {
              this.errors.push(
                this.textErreur.e2CIdassocienomListeTypeidErreur
              );
              this.hasError = true;
            }
          }
          ///*********************** Variante
          // variante nom 0
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
              .idVariante0.check
          ) {
            // Si la checkbox est egale a true
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
                .idVariante0.nom
            ) {
              this.errors.push(
                this.textErreur[this.textErreur.e2OVariantenomInputNomErreur1]
              );
              this.hasError = true;
            }
          }
          // variante nom 1
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
              .idVariante1.check
          ) {
            // Si la checkbox est egale a true
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
                .idVariante1.nom
            ) {
              this.errors.push(
                this.textErreur[this.textErreur.e2OVariantenomInputNomErreur1]
              );
              this.hasError = true;
            }
          }
          // variante nom 2
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
              .idVariante2.check
          ) {
            // Si la checkbox est egale a true
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
                .idVariante2.nom
            ) {
              this.errors.push(
                this.textErreur[this.textErreur.e2OVariantenomInputNomErreur1]
              );
              this.hasError = true;
            }
          }
          // variante nom 3
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
              .idVariante3.check
          ) {
            // Si la checkbox est egale a true
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
                .idVariante3.nom
            ) {
              this.errors.push(
                this.textErreur[this.textErreur.e2OVariantenomInputNomErreur1]
              );
              this.hasError = true;
            }
          }
          // variante nom 4
          if (
            this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
              .idVariante4.check
          ) {
            // Si la checkbox est egale a true
            if (
              !this.isniFormsOrga.isniFormsOrga.donneeIdentification.variante
                .idVariante4.nom
            ) {
              this.errors.push(
                this.textErreur[this.textErreur.e2OVariantenomInputNomErreur1]
              );
              this.hasError = true;
            }
          }
        }
      }
    },
    /**
     * Gestion des erreurs dans la step3
     */
    getErrorsStep3() {
      if (this.step == 3) {
        // vérification des caractères non latins
        if (
          !this.isLatinCode(
            JSON.stringify(this.isniFormsOrga.isniFormsOrga.oeuvre)
          )
        ) {
          this.errors.push(this.textErreur.formTexteLatinErreur);
          this.hasError = true;
        } else {
          // Si les champs est vide ou incorrect alors on affiche l'erreur

          // oeuvre0
          // type oeuvre
          if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre0.typeOeuvre) {
            this.errors.push(this.textErreur.e3CInfosoeuvreListeTypeErreur);
            this.hasError = true;
          }
          // role oeuvre
          if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre0.roleOeuvre) {
            this.errors.push(this.textErreur.e3CInfosoeuvreListeRoleErreur);
            this.hasError = true;
          }
          // theme oeuvre
          if (
            !this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre0.thematiqueOeuvre
          ) {
            this.errors.push(
              this.textErreur.e3CInfosoeuvreListeThematiqueErreur
            );
            this.hasError = true;
          }
          // titre oeuvre
          if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre0.titreOeuvre) {
            this.errors.push(
              this.textErreur.e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
            );
            this.hasError = true;
          }
          // oeuvre1
          if (this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre1.ajoutCheck) {
            // type oeuvre
            if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre1.typeOeuvre) {
              this.errors.push(this.textErreur.e3CInfosoeuvreListeTypeErreur);
              this.hasError = true;
            }
            // role oeuvre
            if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre1.roleOeuvre) {
              this.errors.push(this.textErreur.e3CInfosoeuvreListeRoleErreur);
              this.hasError = true;
            }
            // theme oeuvre 1
            if (
              !this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre1
                .thematiqueOeuvre
            ) {
              this.errors.push(
                this.textErreur.e3CInfosoeuvreListeThematiqueErreur
              );
              this.hasError = true;
            }
            // titre oeuvre
            if (
              !this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre1.titreOeuvre
            ) {
              this.errors.push(
                this.textErreur
                  .e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
              );
              this.hasError = true;
            }
          }
          // oeuvre2
          if (this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre2.ajoutCheck) {
            // type oeuvre
            if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre2.typeOeuvre) {
              this.errors.push(this.textErreur.e3CInfosoeuvreListeTypeErreur);
              this.hasError = true;
            }
            // role oeuvre
            if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre2.roleOeuvre) {
              this.errors.push(this.textErreur.e3CInfosoeuvreListeRoleErreur);
              this.hasError = true;
            }
            // theme oeuvre
            if (
              !this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre2
                .thematiqueOeuvre
            ) {
              this.errors.push(
                this.textErreur.e3CInfosoeuvreListeThematiqueErreur
              );
              this.hasError = true;
            }
            // titre oeuvre
            if (
              !this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre2.titreOeuvre
            ) {
              this.errors.push(
                this.textErreur
                  .e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
              );
              this.hasError = true;
            }
          }
          // oeuvre3
          if (this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre3.ajoutCheck) {
            // type oeuvre
            if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre3.typeOeuvre) {
              this.errors.push(this.textErreur.e3CInfosoeuvreListeTypeErreur);
              this.hasError = true;
            }
            // role oeuvre
            if (!this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre3.roleOeuvre) {
              this.errors.push(this.textErreur.e3CInfosoeuvreListeRoleErreur);
              this.hasError = true;
            }
            // theme oeuvre
            if (
              !this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre3
                .thematiqueOeuvre
            ) {
              this.errors.push(
                this.textErreur.e3CInfosoeuvreListeThematiqueErreur
              );
              this.hasError = true;
            }
            // titre oeuvre
            if (
              !this.isniFormsOrga.isniFormsOrga.oeuvre.idOeuvre3.titreOeuvre
            ) {
              this.errors.push(
                this.textErreur
                  .e3PInfosoeuvreInputTitreoeuvrecollaborativeErreur
              );
              this.hasError = true;
            }
          }
        }
      }
    },
    /**
     * Gestion des erreurs dans la step4
     */
    getErrorsStep4() {
      this.errors = [];
      if (this.step == 4) {
        // vérification des caractères non latins
        if (
          !this.isLatinCode(
            JSON.stringify(this.isniFormsOrga.isniFormsOrga.relation)
          )
        ) {
          this.errors.push(this.textErreur.formTexteLatinErreur);
          this.hasError = true;
        } else {
          // Si les champs est vide ou incorrect alors on affiche l'erreur

          // relation personne
          if (this.isniFormsOrga.isniFormsOrga.relation.relationProCheck) {
            this.hasError = true;
            // idPersonne0
            // type
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idPersonne0
                .typeRelation
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            // nom
            if (!this.isniFormsOrga.isniFormsOrga.relation.idPersonne0.nom) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneInputNomErreur
              );
              this.hasError = true;
            }
          }
          // idPersonne 1
          if (
            this.isniFormsOrga.isniFormsOrga.relation.idPersonne1
              .relationProCheck
          ) {
            this.hasError = true;
            // type
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idPersonne1
                .typeRelation
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            // nom
            if (!this.isniFormsOrga.isniFormsOrga.relation.idPersonne1.nom) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneInputNomErreur
              );
              this.hasError = true;
            }
          }
          // idPersonne 2
          if (
            this.isniFormsOrga.isniFormsOrga.relation.idPersonne2
              .relationProCheck
          ) {
            this.hasError = true;
            // type
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idPersonne2
                .typeRelation
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            // nom
            if (!this.isniFormsOrga.isniFormsOrga.relation.idPersonne2.nom) {
              this.errors.push(
                this.textErreur.e4CRelationpersonneInputNomErreur
              );
              this.hasError = true;
            }
          }

          // relation organisme
          if (
            this.isniFormsOrga.isniFormsOrga.relation.relationOrganismeCheck
          ) {
            this.hasError = true;
            // idOrganisme0
            // type
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idOrganisme0
                .typeRelation
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            // nom
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idOrganisme0
                .nomOrganisme
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeInputNomErreur
              );
              this.hasError = true;
            }
          }

          if (
            this.isniFormsOrga.isniFormsOrga.relation.idOrganisme1
              .relationProCheck
          ) {
            this.hasError = true;
            // idOrganisme1
            // type
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idOrganisme1
                .typeRelation
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            // nom
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idOrganisme1
                .nomOrganisme
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeInputNomErreur
              );
              this.hasError = true;
            }
          }

          if (
            this.isniFormsOrga.isniFormsOrga.relation.idOrganisme2
              .relationOrganismeCheck
          ) {
            this.hasError = true;
            // idOrganisme2
            // type
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idOrganisme2
                .typeRelation
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeListeTyperelationErreur
              );
              this.hasError = true;
            }
            // nom
            if (
              !this.isniFormsOrga.isniFormsOrga.relation.idOrganisme2
                .nomOrganisme
            ) {
              this.errors.push(
                this.textErreur.e4CRelationorganismeInputNomErreur
              );
              this.hasError = true;
            }
          }
        }
      }
    },
    isLatinCode: function (str) {
      // check https://en.wikipedia.org/wiki/Latin_script_in_Unicode
      // eslint-disable-next-line
      const regexRule = /^[\u0000-\u00FF]+$/gmu;
      const isLatin = regexRule.test(str);

      return isLatin;
    },
  },
  computed: {
    /**
     ** activation du bouton suivant sur la step1, valide les consentenents et le champs email vide
     */
    activButtonStep1() {
      if (
        !this.isniFormsOrga.isniFormsOrga.consentement.admissible ||
        !this.isniFormsOrga.isniFormsOrga.consentement.information ||
        !this.isniFormsOrga.isniFormsOrga.consentement.demandeIsni ||
        !this.isniFormsOrga.isniFormsOrga.demandeur.langueFormulaire ||
        !this.isniFormsOrga.isniFormsOrga.demandeur.email
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
     ** activation du bouton suivant sur la step1
     */
    activButtonStep4() {
      if (
        !this.isniFormsOrga.isniFormsOrga.validationForm.attesteAutorise ||
        !this.isniFormsOrga.isniFormsOrga.validationForm.attesteRenseignement
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  /**
   ** mise en place du js de materialize
   */
  mounted() {
    // redirection when user reload the page
    if (!this.textFormulaire) {
      this.$router.push({ name: "Home" });
    }

    // treatment after email validation
    if (this.jumpToStep && typeof this.jumpToStep === "number") {
      this.step = this.jumpToStep;
    }

    this.goToTopForm();
  },
  /**
   ** mise a jour du js de materialize
   */
  updated() {
    M.AutoInit();
    // si on passe a la step2 alors go to top
    if (this.step >= 2 && this.step <= 3) {
      this.goToTopForm();
    }
  },
  created() {
    // si on passe a la step2 alors go to top
    if (this.step >= 2 && this.step <= 3) {
      this.goToTopForm();
    }
  },
};
</script>
<style scoped lang="scss">
.h-btn-darken:hover {
  background-color: #0d47a1 !important;
}
.h-btn-light:hover {
  background-color: #03a9f4 !important;
}
.h-btn-amber:hover {
  background-color: #ff6f00 !important;
}
.h-btn-green:hover {
  background-color: #388e3c !important;
}
.pd-10 {
  padding: 10px;
}
.bg-grey {
  background-color: #f5f5f5;
}
.br-10 {
  border-radius: 10px;
}
.brd-1 {
  border: solid 1px grey;
}

.wrapper-btn {
  .d-flex {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
</style>

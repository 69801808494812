<template>
  <div class="col s12 brd-5 pg-10">
    <div class="col s12">
      <!-- {{ oeuvre }} -->
      <div class="input-field col s12 m6 l6">
        <select v-model="isniForm.oeuvre.idOeuvre1.typeOeuvre" name="Toeuvre1">
          <option value disabled selected
            >{{ dataText.eCListeTexteParDefaut }}
          </option>
          <option
            v-for="(ref, index) in dataSelect.RefTypeOeuvre"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label :class="{ error: hasError }" for="Toeuvre1">
          {{ dataText.e3CInfosoeuvreListeTypeLabel }}
          <span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e3CInfosoeuvreListeTypeAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </label>
      </div>
      <!-- {{ role oeuvre }} -->
      <div class="input-field col s12 m6 l6">
        <select v-model="isniForm.oeuvre.idOeuvre1.roleOeuvre" name="Orole1">
          <option value disabled selected>{{
            dataText.eCListeTexteParDefaut
          }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefRoleDemandeur"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label :class="{ error: hasError }" for="Orole1">
          {{ dataText.e3CInfosoeuvreListeRoleLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
      <!-- {{ thematique oeuvre }} -->
      <div class="input-field col s12 m8 l8">
        <select
          v-model="isniForm.oeuvre.idOeuvre1.thematiqueOeuvre"
          name="Otheme1"
        >
          <option value disabled selected>{{
            dataText.eCListeTexteParDefaut
          }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefThematique"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
        <label for="Otheme1" :class="{ error: hasError }">
          {{ dataText.e3CInfosoeuvreListeThematiqueLabel }}
          <span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e3OInfosoeuvreListeThematiqueAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </label>
      </div>
    </div>

    <!-- {{  }} -->
    <div class="col s12">
      <div class="input-field col s12 m6 l6">
        <input
          id="Toeuvre1"
          type="text"
          class="validate"
          v-model="isniForm.oeuvre.idOeuvre1.titreOeuvre"
        />
        <label
          for="Toeuvre1"
          :class="{
            error: hasError,
            active: !isniForm.oeuvre.idOeuvre1.titreOeuvre == ''
          }"
        >
          {{ dataText.e3CInfosoeuvreInputTitreoeuvreLabel }}
          <span class="red-text">*</span>
          <Isni-Tooltip :content="dataText.e3CInfosoeuvreInputTitreoeuvreAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </label>
      </div>
      <div class="input-field col s12 m6 l6">
        <input
          id="StitreOeuvre1"
          type="text"
          class="validate"
          v-model="isniForm.oeuvre.idOeuvre1.sousTitreOeuvre"
        />
        <label for="StitreOeuvre1" :class="{active: !isniForm.oeuvre.idOeuvre1.sousTitreOeuvre == ''}">
          {{ dataText.e3CInfosoeuvreInputSoustitreoeuvreLabel }}
          <Isni-Tooltip :content="dataText.e3CInfosoeuvreInputSoustitreoeuvreAc">
            <icon iconText="help"/>
          </Isni-Tooltip>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "./../../elements/Icon";
import IsniTooltip from "./../../elements/IsniTooltip";

export default {
  name: "oeuvreOrganisme1",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    hasError: Boolean
  },
  data() {
    return {};
  },
  components: { Icon, IsniTooltip }
};
</script>

<template>
  <div class="row">
    <div class="row">
      <div class="col s12">
        <h3 class="h4-title light-blue-text text-darken-4">
          {{ dataText.e4CFormulaireTitre }}
        </h3>
      </div>
    </div>
    <!-- {{ personne }} -->
    <div class="row z-depth-2 step4--section__content-personne">
      <div class="col s12">
        <p class="light-blue-text text-darken-4">
          {{ dataText.e4ORelationpersonneSoustitreTexte }}
        </p>
        <p>
          <label>
            <input
              name="group2"
              class="with-gap"
              type="radio"
              :value="false"
              v-model="isniForm.relation.relationProCheck"
            />
            <span>{{ dataText.formRbNon }}</span>
          </label>
        </p>
        <p>
          <label>
            <input
              name="group2"
              class="with-gap"
              type="radio"
              :value="true"
              checked
              v-model="isniForm.relation.relationProCheck"
            />
            <span>{{ dataText.formRbOui }}</span>
          </label>
        </p>
      </div>
      <div
        class="col s12 l12"
        v-show="isniForm.relation.relationProCheck == true"
      >
        <!-- {{ relation }} -->
        <div class="input-field col s12 m8 l4">
          <select
            v-model="isniForm.relation.idPersonne0.typeRelation"
            name="RtypeRelation"
          >
            <option value disabled selected>{{
              dataText.eCListeTexteParDefaut
            }}</option>
            <option
              v-for="(ref,
              index) in dataSelect.RefTypeDemandeurOrgaRelationPers"
              :value="index"
              :key="index"
              >{{ ref }}</option
            >
          </select>
          <label
            class="{ error: hasError }"
            for="RtypeRelation"
            :class="{
              error: hasError,
              active: !isniForm.relation.idPersonne0.nom == ''
            }"
          >
            {{ dataText.e4CRelationpersonneListeTyperelationLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e4CRelationpersonneListeTyperelationAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
        <div class="input-field col s12 m6 l4">
          <input
            id="RnomFamille"
            type="text"
            class="validate"
            v-model="isniForm.relation.idPersonne0.nom"
          />
          <label
            for="RnomFamille"
            :class="{
              error: hasError,
              active: !isniForm.relation.idPersonne0.nom == ''
            }"
          >
            {{ dataText.e4CRelationpersonneInputNomLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e4CRelationpersonneInputNomAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
        <div class="input-field col s12 m6 l4">
          <input
            id="Rprenom"
            type="text"
            class="validate"
            v-model="isniForm.relation.idPersonne0.prenom"
          />
          <label for="Rprenom" :class="{active: !isniForm.relation.idPersonne0.prenom == ''}"
            >{{ dataText.e4CRelationpersonneInputPrenomLabel }}
          </label>
        </div>

        <div class="col s12">
          <p>
            <label>
              <input
                type="checkbox"
                :value="true"
                v-model="isniForm.relation.idPersonne1.relationProCheck"
              />
              <template v-if="!isniForm.relation.idPersonne1.relationProCheck">
                <span class="black-text fw-400">{{
                  dataText.e4CRelationpersonneCbAjoutoccurrence1Texte
                }}</span>
              </template>
              <template v-else>
                <span class="black-text fw-400">{{
                  dataText.e4CRelationpersonneCbRetraitoccurrence1Texte
                }}</span>
              </template>
            </label>
          </p>
        </div>

        <!-- {{ champs repetable relation perso }} -->
        <app-section-relation-perso-1
          :isniForm="isniForm"
          :hasError="hasError"
          v-show="isniForm.relation.idPersonne1.relationProCheck"
          :dataText="dataText"
          :dataSelect="dataSelect"
        ></app-section-relation-perso-1>

        <div class="col s12">
          <p>
            <label>
              <input
                type="checkbox"
                :value="true"
                v-model="isniForm.relation.idPersonne2.relationProCheck"
              />
              <template v-if="!isniForm.relation.idPersonne2.relationProCheck">
                <span class="black-text fw-400">{{
                  dataText.e4CRelationpersonneCbAjoutoccurrence2Texte
                }}</span>
              </template>
              <template v-else>
                <span class="black-text fw-400">{{
                  dataText.e4CRelationpersonneCbRetraitoccurrence2Texte
                }}</span>
              </template>
            </label>
          </p>
        </div>
        <app-section-relation-perso-2
          :isniForm="isniForm"
          :hasError="hasError"
          :dataText="dataText"
          :dataSelect="dataSelect"
        ></app-section-relation-perso-2>
      </div>
    </div>

    <!-- {{ organisme }} -->
    <div class="row z-depth-2 step4--section__content-organisme">
      <div class="col s12">
        <p class="light-blue-text text-darken-4">
          {{ dataText.e4ORelationorganismeSoustitreTexte }}
        </p>
        <p>
          <label>
            <input
              name="group1"
              type="radio"
              class="with-gap"
              :value="false"
              v-model="isniForm.relation.relationOrganismeCheck"
            />
            <span>{{ dataText.formRbNon }}</span>
          </label>
        </p>
        <p>
          <label>
            <input
              name="group1"
              type="radio"
              :value="true"
              class="with-gap"
              checked
              v-model="isniForm.relation.relationOrganismeCheck"
            />
            <span>{{ dataText.formRbOui }}</span>
          </label>
        </p>
      </div>
      <div
        class="col s12 l12"
        v-show="isniForm.relation.relationOrganismeCheck == true"
      >
        <!-- {{ relation }} -->
        <div class="input-field col s12 m6 l6">
          <select
            v-model="isniForm.relation.idOrganisme0.typeRelation"
            name="RtypeOrga"
          >
            <option value disabled selected>{{
              dataText.eCListeTexteParDefaut
            }}</option>
            <option
              v-for="(ref,
              index) in dataSelect.RefTypeDemandeurOrgaRelationOrga"
              :value="index"
              :key="index"
              >{{ ref }}</option
            >
          </select>
          <label :class="{ error: hasError }" for="RtypeOrga">
            {{ dataText.e4CRelationorganismeListeTyperelationLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e4CRelationorganismeListeTyperelationAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
        <div class="input-field col s12 m6 l6">
          <input
            id="RnomOrga"
            type="text"
            class="validate"
            v-model="isniForm.relation.idOrganisme0.nomOrganisme"
          />
          <label
            for="RnomOrga"
            :class="{
              error: hasError,
              active: !isniForm.relation.idOrganisme0.nomOrganisme == ''
            }"
          >
            {{ dataText.e4CRelationorganismeInputNomLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e4CRelationorganismeInputNomAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
        <div class="col s12">
          <p>
            <label>
              <input
                type="checkbox"
                :value="true"
                v-model="isniForm.relation.idOrganisme1.relationProCheck"
              />
              <template v-if="!isniForm.relation.idOrganisme1.relationProCheck">
                <span class="black-text fw-400">{{
                  dataText.e4CRelationorganismeCbAjoutoccurrence1Texte
                }}</span>
              </template>
              <template v-else>
                <span class="black-text fw-400">{{
                  dataText.e4CRelationorganismeCbRetraitoccurrence1Texte
                }}</span>
              </template>
            </label>
          </p>
        </div>

        <!-- {{ champs repetable relation organisme }} -->
        <app-section-relation-organisme-perso-1
          :isniForm="isniForm"
          :hasError="hasError"
          v-show="isniForm.relation.idOrganisme1.relationProCheck"
          :dataText="dataText"
          :dataSelect="dataSelect"
        ></app-section-relation-organisme-perso-1>

        <div class="col s12">
          <p>
            <label>
              <input
                type="checkbox"
                value="oui"
                v-model="isniForm.relation.idOrganisme2.relationProCheck"
              />
              <template v-if="!isniForm.relation.idOrganisme2.relationProCheck">
                <span class="black-text fw-400">{{
                  dataText.e4CRelationorganismeCbAjoutoccurrence2Texte
                }}</span>
              </template>
              <template v-else>
                <span class="black-text fw-400">{{
                  dataText.e4CRelationorganismeCbRetraitoccurrence2Texte
                }}</span>
              </template>
            </label>
          </p>
        </div>
        <app-section-relation-organisme-perso-2
          :isniForm="isniForm"
          :hasError="hasError"
          v-show="isniForm.relation.idOrganisme2.relationProCheck"
          :dataText="dataText"
          :dataSelect="dataSelect"
        ></app-section-relation-organisme-perso-2>
      </div>
    </div>

    <div class="row z-depth-2 border-tour-blue step4--section__consentement">
      <div class="col s12">
        <p>
          <label>
            <input
              type="checkbox"
              v-model="isniForm.validationForm.attesteAutorise"
            />
            <span>{{ dataText.e4CConsentementCbTexte }}</span>
          </label>
        </p>
        <p>
          <label>
            <input
              type="checkbox"
              v-model="isniForm.validationForm.attesteRenseignement"
            />
            <span> {{ dataText.e4CExactitudeCbTexte }}</span>
          </label>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./../elements/Icon";
import IsniTooltip from "../elements/IsniTooltip";
import RelationOrgaPerso1 from "./../sections/step4/organisme/RelationOrgaPerso1";
import RelationOrgaPerso2 from "./../sections/step4/organisme/RelationOrgaPerso2";
import RelationPerso1 from "./../sections/step4/organisme/RelationPerso1";
import RelationPerso2 from "./../sections/step4/organisme/RelationPerso2";

export default {
  name: "FormOrgaStep4",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    "data-position": {
      type: String
    },
    "data-tooltip": {
      type: String
    },
    hasError: Boolean
  },
  components: {
    Icon,
    IsniTooltip,
    "app-section-relation-organisme-perso-1": RelationOrgaPerso1,
    "app-section-relation-organisme-perso-2": RelationOrgaPerso2,
    "app-section-relation-perso-1": RelationPerso1,
    "app-section-relation-perso-2": RelationPerso2
  }
};
</script>
<style lang="scss" scoped>
    .h4-title{
        font-size: 2.28rem;
        line-height: 110%;
        margin: 1.52rem 0 .912rem 0;
    }
</style>
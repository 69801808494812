<template>
  <div class="row">
    <div class="row">
      <div class="col s12">
        <h3 class="h4-title light-blue-text text-darken-4">
          {{ dataText.e3OFormulaireTitre }}
        </h3>
      </div>
    </div>
    <div class="row z-depth-2">
      <div class="col s12 mt-15">
        <!-- {{ oeuvre }} -->
        <div class="input-field col s12 m6 l6">
          <select v-model="isniForm.oeuvre.idOeuvre0.typeOeuvre" name="Toeuvre">
            <option value disabled selected
              >{{ dataText.eCListeTexteParDefaut }}
            </option>
            <option
              v-for="(ref, index) in dataSelect.RefTypeOeuvre"
              :value="index"
              :key="index"
              >{{ ref }}</option
            >
          </select>
          <label :class="{ error: hasError }" for="Toeuvre">
            {{ dataText.e3CInfosoeuvreListeTypeLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e3CInfosoeuvreListeTypeAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
        <!-- {{ role oeuvre }} -->
        <div class="input-field col s12 m6 l6">
          <select
            v-model="isniForm.oeuvre.idOeuvre0.roleOeuvre"
            name="Orole"
          >
            <option value disabled selected>{{
              dataText.eCListeTexteParDefaut
            }}</option>
            <option
              v-for="(ref, index) in dataSelect.RefRoleDemandeur"
              :value="index"
              :key="index"
              >{{ ref }}</option
            >
          </select>
          <label :class="{ error: hasError }" for="Orole">
            {{ dataText.e3CInfosoeuvreListeRoleLabel }}
            <span class="red-text">*</span>
          </label>
        </div>
        <!-- {{ thematique oeuvre }} -->
        <div class="input-field col s12 m8 l8" >
          <select
            v-model="isniForm.oeuvre.idOeuvre0.thematiqueOeuvre"
            name="Otheme"
          >
            <option value disabled selected>{{
              dataText.eCListeTexteParDefaut
            }}</option>
            <option
              v-for="(ref, index) in dataSelect.RefThematique"
              :value="index"
              :key="index"
              >{{ ref }}</option
            >
          </select>
          <label for="Otheme" :class="{ error: hasError }">
            {{ dataText.e3CInfosoeuvreListeThematiqueLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e3OInfosoeuvreListeThematiqueAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
      </div>

      <!-- {{  }} -->
      <div class="col s12">
        <div class="input-field col s12 m6 l6">
          <input
            id="Toeuvre"
            type="text"
            class="validate"
            v-model="isniForm.oeuvre.idOeuvre0.titreOeuvre"
          />
          <label
            for="Toeuvre"
            :class="{
              error: hasError,
              active: !isniForm.oeuvre.idOeuvre0.titreOeuvre == ''
            }"
          >
            {{ dataText.e3CInfosoeuvreInputTitreoeuvreLabel }}
            <span class="red-text">*</span>
            <Isni-Tooltip :content="dataText.e3CInfosoeuvreInputTitreoeuvreAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
        <div class="input-field col s12 m6 l6">
          <input
            id="StitreOeuvre"
            type="text"
            class="validate"
            v-model="isniForm.oeuvre.idOeuvre0.sousTitreOeuvre"
          />
          <label for="StitreOeuvre" :class="{active: !isniForm.oeuvre.idOeuvre0.sousTitreOeuvre == ''}">
            {{ dataText.e3CInfosoeuvreInputSoustitreoeuvreLabel }}
            <Isni-Tooltip :content="dataText.e3CInfosoeuvreInputSoustitreoeuvreAc">
              <icon iconText="help"/>
            </Isni-Tooltip>
          </label>
        </div>
      </div>

      <div class="col s12">
        <p>
          <label>
            <input
              type="checkbox"
              :value="true"
              v-model="isniForm.oeuvre.idOeuvre1.ajoutCheck"
            />
            <template v-if="!isniForm.oeuvre.idOeuvre1.ajoutCheck">
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbAjoutoccurrence1Texte
              }}</span>
            </template>
            <template v-else>
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbRetraitoccurrence1Texte
              }}</span>
            </template>
          </label>
        </p>
      </div>

      <!-- {{  champs repetable }} -->

      <app-section-oeuvre-organisme-1
        :isniForm="isniForm"
        :hasError="hasError"
        v-show="isniForm.oeuvre.idOeuvre1.ajoutCheck"
        :dataText="dataText"
        :dataSelect="dataSelect"
      >
      </app-section-oeuvre-organisme-1>

      <div class="col s12">
        <p>
          <label>
            <input
              type="checkbox"
              :value="true"
              v-model="isniForm.oeuvre.idOeuvre2.ajoutCheck"
            />
            <template v-if="!isniForm.oeuvre.idOeuvre2.ajoutCheck">
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbAjoutoccurrence2Texte
              }}</span>
            </template>
            <template v-else>
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbRetraitoccurrence2Texte
              }}</span>
            </template>
          </label>
        </p>
      </div>

      <app-section-oeuvre-organisme-2
        :isniForm="isniForm"
        :hasError="hasError"
        v-show="isniForm.oeuvre.idOeuvre2.ajoutCheck"
        :dataText="dataText"
        :dataSelect="dataSelect"
      >
      </app-section-oeuvre-organisme-2>
      <div class="col s12">
        <p>
          <label>
            <input
              type="checkbox"
              :value="true"
              v-model="isniForm.oeuvre.idOeuvre3.ajoutCheck"
            />
            <template v-if="!isniForm.oeuvre.idOeuvre3.ajoutCheck">
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbAjoutoccurrence3Texte
              }}</span>
            </template>
            <template v-else>
              <span class="black-text fw-400">{{
                dataText.e3CInfosoeuvreCbRetraitoccurrence3Texte
              }}</span>
            </template>
          </label>
        </p>
      </div>
      <app-section-oeuvre-organisme-3
        :isniForm="isniForm"
        :hasError="hasError"
        v-show="isniForm.oeuvre.idOeuvre3.ajoutCheck"
        :dataText="dataText"
        :dataSelect="dataSelect"
      >
      </app-section-oeuvre-organisme-3>
    </div>
  </div>
</template>

<script>
import Icon from "./../elements/Icon";
import IsniTooltip from "../elements/IsniTooltip";
import OeuvreOrganisme1 from "./../sections/step3/oeuvreOrganisme1";
import OeuvreOrganisme2 from "./../sections/step3/oeuvreOrganisme2";
import OeuvreOrganisme3 from "./../sections/step3/oeuvreOrganisme3";

export default {
  name: "FormOrgaStep3",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    "data-position": {
      type: String
    },
    "data-tooltip": {
      type: String
    },
    hasError: Boolean
  },
  components: {
    Icon,
    IsniTooltip,
    "app-section-oeuvre-organisme-1": OeuvreOrganisme1,
    "app-section-oeuvre-organisme-2": OeuvreOrganisme2,
    "app-section-oeuvre-organisme-3": OeuvreOrganisme3
  }
};
</script>
<style lang="scss" scoped>
    .h4-title{
        font-size: 2.28rem;
        line-height: 110%;
        margin: 1.52rem 0 .912rem 0;
    }
</style>
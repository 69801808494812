<!--
Composant Date
Affichage les champs date de naissance et de deces dans le formulaires


-->
<template>
  <!-- {{DATE Debut & Fin}} -->
  <div class="">
    <div class="col s12 m6 l6">
      <p class="light-blue-text text-darken-4">
        {{ dataText.e2OIdpubliqueDatedebSoustitreTexte }}
      </p>
      <div class="col s12 l4">
        <label :class="[ (hasError && (errorDetail.dateDebutAnnee || errorDetail.dateDebutAll)) ? 'error' :
        'black-text']"
          >{{ dataText.e2OIdpubliqueDatedebInputAnneeLabel }}
          <span class="red-text">*</span></label
        >
        <input
          ref="debutAnnee"
          type="number"
          name="anneeDebut"
          min="0"
          title="Entre 0 et 2020"
          max="2020"
          class="validate"
          v-model="isniForm.donneeIdentification.date.debutUtilisationNom.annee"
          @blur="onExit('debutAnnee', 'debutUtilisationNom')"
        />
      </div>
      <div class="col s12 l4">
        <label :class="[ hasError && errorDetail.dateDebutAll ? 'error' : 'black-text']">{{
          dataText.e2OIdpubliqueDatedebInputMoisLabel
        }}</label>
        <select
          v-model="isniForm.donneeIdentification.date.debutUtilisationNom.mois"
        >
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option value=""></option>
          <option
            v-for="(ref, index) in dataSelect.RefMois"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
      </div>
      <div class="col s12 l4">
        <label :class="[ hasError && errorDetail.dateDebutAll ? 'error' : 'black-text']">{{
          dataText.e2OIdpubliqueDatedebInputJourLabel
        }}</label>
        <select
          v-model="isniForm.donneeIdentification.date.debutUtilisationNom.jour"
        >
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option value=""></option>
          <template>
            <option v-for="item in 31" :key="item" :value="item">{{
              item
            }}</option>
          </template>
        </select>
      </div>
    </div>

    <div class="col s12 m6 l6 right">
      <p class="light-blue-text text-darken-4">
        {{ dataText.e2OIdpubliqueDatefinSoustitreTexte }}
      </p>
      <div class="col s12 l4">
        <label
            :class="[ (hasError && (errorDetail.dateFinAnnee || errorDetail.dateFinAll)) ? 'error' : 'black-text']">{{
          dataText.e2OIdpubliqueDatefinInputAnneeLabel
        }}</label>
        <input
          ref="finAnnee"
          type="number"
          name="anneeFin"
          min="0"
          title="Entre 0 et 2020"
          max="2020"
          class="validate"
          v-model="isniForm.donneeIdentification.date.finUtilisationNom.annee"
          @blur="onExit('finAnnee', 'finUtilisationNom')"
        />
      </div>
      <div class="col s12 l4">
        <label :class="[ hasError && errorDetail.dateFinAll ? 'error' : 'black-text']">{{
          dataText.e2OIdpubliqueDatefinInputMoisLabel
        }}</label>
        <select
          v-model="isniForm.donneeIdentification.date.finUtilisationNom.mois"
        >
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option value=""></option>
          <option
            v-for="(ref, index) in dataSelect.RefMois"
            :value="index"
            :key="index"
            >{{ ref }}</option
          >
        </select>
      </div>
      <div class="col s12 l4">
        <label :class="[ hasError && errorDetail.dateFinAll ? 'error' : 'black-text']">{{
          dataText.e2OIdpubliqueDatefinInputJourLabel
        }}</label>
        <select
          v-model="isniForm.donneeIdentification.date.finUtilisationNom.jour"
        >
          <option value disabled selected>{{ dataText.eCListeTexteParDefaut }}</option>
          <option value=""></option>
          <option v-for="item in 31" :key="item" :value="item">{{
            item
          }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateOrganisme",
  props: {
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    isniForm: {
      type: Object,
      required: true
    },
    "data-position": {
      type: String
    },
    "data-tooltip": {
      type: String
    },
    hasError: Boolean,
    errorDetail: {
      type: Object
    }
  },
  data() {
    return {
      // year: number,
      // month: number,
      // day: number
    };
  },
  computed: {
    // dateFormat: function () {
    //
    //   this.year = this.isniForm.donneeIdentification.date_naissance.annee
    //   this.month = this.isniForm.donneeIdentification.date_naissance.mois
    //   this.day = isniForm.donneeIdentification.date_naissance.jour
    //   this.birthdayDate = this.year + this.month + this.day
    //   // return something
    //   return console.debug(this.birthdayDate);
    // }
  },
  methods: {
    onExit: function(elemRef, modalityDate) {
      if (this.$refs[elemRef]) {
        this.$refs[elemRef].value = this.isniForm.donneeIdentification.date[modalityDate].annee;
      }
    }
  }
};
</script>

<style></style>

<template>
  <!-- {{ relation orga 2}} -->
  <div class="col s12 brd-5 pg-10">
    <div class="input-field col s12 m4 l6">
      <select
        v-model="isniForm.relation.idOrganisme2.typeRelation"
        name="RtypeOrga2"
      >
        <option value disabled selected>{{
          dataText.eCListeTexteParDefaut
        }}</option>
        <option
          v-for="(ref, index) in dataSelect.RefTypeDemandeurOrgaRelationOrga"
          :value="index"
          :key="index"
          >{{ ref }}</option
        >
      </select>
      <label :class="{ error: hasError }" for="RtypeOrga2">
        {{ dataText.e4CRelationpersonneListeTyperelationLabel }}
        <span class="red-text">*</span>
        <Isni-Tooltip :content="dataText.e4CRelationorganismeListeTyperelationAc">
          <icon iconText="help"/>
        </Isni-Tooltip>
      </label>
    </div>
    <div class="input-field col s6 m4 l6">
      <input
        id="RnomOrga2"
        type="text"
        class="validate"
        v-model="isniForm.relation.idOrganisme2.nomOrganisme"
      />
      <label
        for="RnomOrga2"
        :class="{
          active: !isniForm.relation.idOrganisme2.nomOrganisme == ''
        }"
      >
        {{ dataText.e4CRelationorganismeInputNomLabel }}
        <span class="red-text">*</span>
        <Isni-Tooltip :content="dataText.e4CRelationorganismeInputNomAc">
          <icon iconText="help"/>
        </Isni-Tooltip>
      </label>
    </div>
  </div>
</template>

<script>
import Icon from "./../../../elements/Icon";
import IsniTooltip from "./../../../elements/IsniTooltip";

export default {
  name: "RelationOrgaPerso2",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true
    },
    hasError: Boolean
  },
  components: { Icon, IsniTooltip }
};
</script>
<template>
  <div class="col s12">
    <div
      class="col s12 brd-5 pg-10"
      v-if="isniForm.donneeIdentification.variante.idVariante4.check"
    >
      <div class="input-field col s12 m12 l12">
        <input
          id="Vnom4"
          type="text"
          class="validate"
          v-model="isniForm.donneeIdentification.variante.idVariante4.nom"
        />
        <label
          for="Vnom4"
          :class="{
            error: hasError,
            active:
              !isniForm.donneeIdentification.variante.idVariante4.nom == ''
          }"
        >
          {{ dataText.e2OVariantenomInputNomLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VarianteNomOrganisme4",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    hasError: Boolean
  }
};
</script>
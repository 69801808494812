<!--
Composant Step


-->
<template>
  <div>
    <div class="row z-depth-2 wrapper-consent border-tour-blue">
      <div class="col s12 l12">
        <p>
          <label>
            <input
              type="checkbox"
              class="filled-in"
              v-model="isniForm.consentement.admissible"
              name="Cadminssible"
            />
            <span v-html="dataText.e1CConsentementCbTexte"></span>
          </label>
        </p>
        <p>
          <label>
            <input
              type="checkbox"
              class="filled-in"
              v-model="isniForm.consentement.information"
              name="Cinformation"
            />
            <span v-html="dataText.e1OVerificationCbTexte"></span>
          </label>
        </p>
        <p>
          <label>
            <input
              type="checkbox"
              class="filled-in"
              v-model="isniForm.consentement.demandeIsni"
              name="CdemandeIsni"
            />
            <span v-html="dataText.e1OAdmissibiliteCbTexte"></span>
          </label>
        </p>
      </div>
    </div>
    <div class="row z-depth-1 pg-10">
      <!--      TODO: en attendre la version anglaise-->
      <div class="input-field col s12 m6 l6">
        <select v-model="isniForm.demandeur.langueFormulaire" name="langueª">
          <option value disabled selected>{{
            dataText.e1CListeLangueErreur
          }}</option>
          <option
            v-for="(ref, index) in dataSelect.RefLangueCommunication"
            :value="index"
            :key="index"
          >
            {{ ref }}
          </option>
        </select>
        <label :class="{ error: hasError }" for="langue">
          {{ dataText.e1CListeLangueLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
      <div class="input-field col s12 m6 l6">
        <input
          id="email"
          type="email"
          class="validate"
          v-model="isniForm.demandeur.email"
        />
        <label
          for="email"
          :class="{
            error: hasError,
            active: !isniForm.demandeur.email == ''
          }"
        >
          {{ dataText.e1CInputCourrielLabel }}
          <span class="red-text">*</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormOrgaStep1",
  props: {
    isniForm: {
      type: Object,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    },
    dataSelect: {
      type: Object,
      required: true,
    },
    hasError: Boolean
  },
  created: function() {
  }
};
</script>
<style lang="scss" scoped>
.wrapper-consent {
  [type="checkbox"] + span:not(.lever) {
    color: black;
    font-weight: 600;
  }
}
</style>
